import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
	
class Footer extends Component {
    render() {
        return (
			<div className="gios-footer">
				<div>
                    <div>
					    <div className="d-flex flex-column flex-lg-row justify-content-center">
                            <div className="p-3 p-lg-5">
                                <div className="h3 font-weight-bold">Menus</div>
                                {/* HIDE UNTIL NEW MENU IS READY */}
                                {/* <a href={window.location.origin + "/docs/Gios_DineInMenu_2022.pdf"} target="_blank" class="nav-link p-0 m-0">
                                    <div className="txt-white txt-lightgrey-hover">Dine-In</div>
                                </a> */}
                                <a href={window.location.origin + "/docs/Gios_TakeOutMenu_2024.pdf"} target="_blank" class="nav-link p-0 m-0">
                                    <div className="txt-white txt-lightgrey-hover">Take-Out</div>
                                </a>
                                <a href={window.location.origin + "/docs/Gios_CateringMenu_2024.pdf"} target="_blank" class="nav-link p-0 m-0">
                                    <div className="txt-white txt-lightgrey-hover">Catering</div>
                                </a>
                            </div>
                            <div class="p-3 p-lg-5">
                                <div class="h3 font-weight-bold">Contact</div>
                                <div>
                                    <Link to="/contact" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Directions</div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/contact" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Call</div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/contact" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Hours</div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/contact" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Message</div>
                                    </Link>
                                </div>
                            </div>	
                            <div class="p-3 p-lg-5">
                                <div class="h3 font-weight-bold">Site Map</div>
                                <div>
                                    <Link to="/home" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Home</div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/menu" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Menu</div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/gallery" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Gallery</div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/about" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">About</div>
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/contact" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-lightgrey-hover">Contact</div>
                                    </Link>
                                </div>
                            </div>					
                            <div class="p-3 p-lg-5">
                                <div class="h3 font-weight-bold">Social Media</div>
                                <a href="https://www.facebook.com/giovannispizza.quakertown" class="nav-link p-0 m-0">
                                    <div class="txt-white txt-lightgrey-hover">Facebook</div>
                                </a>
                                <a href="https://www.instagram.com/giovannispizzeria/" class="nav-link p-0 m-0">
                                    <div class="txt-white txt-lightgrey-hover">Instagram</div>
                                </a>
                                <a href="https://twitter.com/GIOVANNIS_Qtown" class="nav-link p-0 m-0">
                                    <div class="txt-white txt-lightgrey-hover">Twitter</div>
                                </a>
                            </div>
                        </div>
					</div>
                    <div>
                        <div class="py-4">
                            <div>
                                <div class="d-flex justify-content-center">					
                                    <div class="h4 p-0 m-0 font-weight-bold">Giovanni's Pizzeria Copyright © 1980-{(new Date()).getFullYear()}</div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-center pt-2">	
                                    <div class="pr-1">Powered By</div>
                                    <a href="https://www.divvision33.com/#/" class="nav-link p-0 m-0">
                                        <div class="txt-white txt-divv33-blue-hover">Divvision33</div>
                                    </a>
                                </div>
                            </div>                            
                        </div>
					</div>
				</div>
			</div>
        );
    }
}

export default Footer;
